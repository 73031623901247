<template>
  <div class="BG-box">
    <svg
      width="2790"
      height="1478"
      viewBox="0 0 2790 1478"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="1" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="83" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="165" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="247" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="329" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="411" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="493" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="575" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="657" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="739" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="821" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="903" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="985" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1067" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1149" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1231" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1313" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1395" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="83" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="165" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="247" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="329" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="411" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="493" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="575" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="657" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="739" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="821" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="903" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="985" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1067" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1149" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1231" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1313" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1395" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1477" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1559" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1641" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1723" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1805" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1887" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="1969" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2051" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2133" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2215" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2297" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2379" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2461" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2543" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2625" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2707" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
      <circle cx="2789" cy="1477" r="1" fill="#F7FFF7" fill-opacity="0.24" />
    </svg>
  </div>
</template>

<script>
export default {
  name: "BGComponent",
};
</script>

<style scoped lang="scss">
@use "../styles/variables";
.BG-box {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: -10;
  opacity: 1;
  & svg {
    width: max(2000px, 100vw);
    height: auto;
  }
}
</style>
