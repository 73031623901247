// router/index.js
import { createRouter, createWebHistory } from "vue-router";
import { eventBus } from "../EventBus.js";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

const Home = () => import(/* webpackChunkName: "home" */ "../pages/Home.vue");
const Lab = () => import(/* webpackChunkName: "lab" */ "../pages/Lab.vue");
const ProjectTemplate = () =>
  import(/* webpackChunkName: "project" */ "../pages/ProjectTemplate.vue");

gsap.registerPlugin(ScrollToPlugin);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/lab",
    name: "Lab",
    component: Lab,
  },
  {
    path: "/project/:projectId",
    name: "ProjectTemplate",
    component: ProjectTemplate,
    props: true,
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return {
        selector: to.hash,
        behavior: "smooth",
      };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  const scrollTriggers = ScrollTrigger.getAll();
  scrollTriggers.forEach((trigger) => {
    trigger.kill();
  });
  if (to.href === "/") {
    gsap.to(window, {
      scrollTo: {
        y: 0,
        autoKill: false,
      },
      immediateRender: true,
      delay: 0.7,
      duration: 0.1,
    });
  }

  if (from.name !== undefined) {
    eventBus.emit("startTransition");
  }

  next();
});

router.afterEach((to, from) => {
  setTimeout(() => {
    ScrollTrigger.refresh();
  }, 1000);
});
export default router;
