<template>
  <router-link :to="url" class="button">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "ButtonComponent",
  data() {
    return {};
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
  },
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss">
@use "../../styles/variables";

.button {
  background: variables.$primary-color;
  color: variables.$secondary-color;
  font-size: variables.$font-size-16;
  font-family: variables.$ibm;
  font-weight: 600;
  padding: 8px 16px;
  border-radius: 200px;
  white-space: nowrap;
  height: max-content;
  width: max-content;
}
</style>
