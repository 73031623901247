import { createStore } from "vuex";

export default createStore({
  state: {
    activeAnimation: null,
    isAppMounted: false,
  },
  mutations: {
    SET_ACTIVE_ANIMATION(state, animationName) {
      state.activeAnimation = animationName;
    },
    SET_APP_MOUNTED(state, payload) {
      state.isAppMounted = payload;
    },
  },
  actions: {
    setActiveAnimation({ commit }, animationName) {
      commit("SET_ACTIVE_ANIMATION", animationName);
    },
  },
});
