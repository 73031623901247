<template>
  <footer id="contact" ref="footer">
    <div class="ctaBox">
      <div class="ctaBox_left">
        <div class="ctaBox_left_title">
          <span>Let's</span>
          <span class="text-animate">make</span>
          <span>something</span>
          <span>great</span>
          <span class="text-animate">together</span>
        </div>

        <a
          v-if="!this.isMobile"
          class="buttonBox ctaFooter"
          ref="rightSideA"
          :href="'mailto:' + data.mail"
          @mouseenter="animateUp"
          @mouseleave="animateDown"
        >
          <div class="clip">
            <span>Send me an email</span>
            <svg
              width="24px"
              height="24px"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#0a0a0a"
            >
              <path
                d="M7 9L12 12.5L17 9"
                stroke="#F7FFF7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z"
                stroke="#F7FFF7"
                stroke-width="1.5"
              ></path>
            </svg>
          </div>
          <div class="buttonBox_cache ctaFooter_cache" ref="cache">
            <span>Send me an email</span>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
              ref="mailSvg"
            >
              <path
                d="M9 9L13.5 12L18 9"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M3 13.5H5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 10.5H5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M5 7.5V7C5 5.89543 5.89543 5 7 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H7C5.89543 19 5 18.1046 5 17V16.5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
              ></path>
            </svg></div
        ></a>
      </div>
      <div class="ctaBox_right">
        <div class="ctaBox_right_circleLeft">
          <img
            src="../assets/ui/rayureCircle.svg"
            alt=""
            class="rayure"
            ref="rayure"
          />
        </div>
        <div class="ctaBox_right_circleRight" ref="circleRight"></div>
      </div>
      <div class="ctaBox_left_button ctaFooter_mob" v-if="this.isMobile">
        <a
          v-if="this.isMobile"
          class="buttonBox ctaFooter"
          ref="rightSideA"
          :href="'mailto:' + data.mail"
          @mouseenter="animateUp"
          @mouseleave="animateDown"
        >
          <div class="clip">
            <span>Send me an email</span>
            <svg
              width="24px"
              height="24px"
              stroke-width="1.5"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#0a0a0a"
            >
              <path
                d="M7 9L12 12.5L17 9"
                stroke="#F7FFF7"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M2 17V7C2 5.89543 2.89543 5 4 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H4C2.89543 19 2 18.1046 2 17Z"
                stroke="#F7FFF7"
                stroke-width="1.5"
              ></path>
            </svg>
          </div>
          <div class="buttonBox_cache ctaFooter_cache" ref="cache">
            <span>Send me an email</span>
            <svg
              width="24px"
              height="24px"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              color="#000000"
              ref="mailSvg"
            >
              <path
                d="M9 9L13.5 12L18 9"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M3 13.5H5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M1 10.5H5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              ></path>
              <path
                d="M5 7.5V7C5 5.89543 5.89543 5 7 5H20C21.1046 5 22 5.89543 22 7V17C22 18.1046 21.1046 19 20 19H7C5.89543 19 5 18.1046 5 17V16.5"
                stroke="#000000"
                stroke-width="1.5"
                stroke-linecap="round"
              ></path>
            </svg></div
        ></a>
      </div>
    </div>
    <div class="infoFooter">
      <div class="infoFooter_menu" v-if="!this.isMobile">
        <ul>
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            @mouseenter="enterMenu"
            @mouseleave="leaveMenu"
          >
            <a href="javascript:void(0);" @click="navigate(item)">{{
              item.name
            }}</a>
            <div class="menuHover"></div>
          </li>
        </ul>
      </div>
      <div class="infoFooter_socials" v-if="!this.isMobile">
        <ul>
          <li class="infoFooter_socials_box" v-for="social in data.socials">
            <a :href="social.url">{{ social.name }} </a>

            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="17"
              viewBox="0 0 16 17"
              fill="none"
              class="arrowIcon"
            >
              <path
                d="M2.93846 2.32861V4.20554H10.9998L2 13.2054L3.32323 14.5286L12.3231 5.52877V13.5902H14.2V2.32861H2.93846Z"
                fill="#0a0a0a"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="55"
              height="55"
              viewBox="0 0 55 55"
              fill="none"
              class="pathArrow"
            >
              <path
                ref="pathArrow"
                d="M0.500001 27.5C0.5 42.4117 12.5883 54.5 27.5 54.5C42.4117 54.5 54.5 42.4117 54.5 27.5C54.5 12.5883 42.4117 0.499999 27.5 0.499998C12.5883 0.499996 0.500003 12.5883 0.500001 27.5Z"
                stroke="transparent"
              />
            </svg>
          </li>
        </ul>
      </div>
      <div class="infoFooter_status" v-if="!this.isMobile">
        <ul>
          <li>{{ data.statut }}</li>
          <li>{{ data.address }}</li>
        </ul>
      </div>
      <div class="infoFooter_mob" v-if="this.isMobile">
        <div class="infoFooter_menu">
          <ul>
            <li v-for="(item, index) in menuItems" :key="index">
              <a href="javascript:void(0);" @click="navigate(item)">{{
                item.name
              }}</a>
            </li>
          </ul>
        </div>
        <div class="infoFooter_socials">
          <ul>
            <li class="infoFooter_socials_box" v-for="social in data.socials">
              <a target="_blank" :href="social.url">{{ social.name }} </a>

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="17"
                viewBox="0 0 16 17"
                fill="none"
                class="arrowIcon"
              >
                <path
                  d="M2.93846 2.32861V4.20554H10.9998L2 13.2054L3.32323 14.5286L12.3231 5.52877V13.5902H14.2V2.32861H2.93846Z"
                  fill="#0a0a0a"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="55"
                height="55"
                viewBox="0 0 55 55"
                fill="none"
                class="pathArrow"
              >
                <path
                  ref="pathArrow"
                  d="M0.500001 27.5C0.5 42.4117 12.5883 54.5 27.5 54.5C42.4117 54.5 54.5 42.4117 54.5 27.5C54.5 12.5883 42.4117 0.499999 27.5 0.499998C12.5883 0.499996 0.500003 12.5883 0.500001 27.5Z"
                  stroke="transparent"
                />
              </svg>
            </li>
          </ul>
        </div>
      </div>
      <div class="infoFooter_credits">
        <ul>
          <li>
            Dev.
            <a href="https://www.linkedin.com/in/lyman-abid/" target="_blank">
              <u>Lyman Abid</u>
            </a>
          </li>
          <li>
            Design.
            <a href="https://noemieheuze.fr/" target="_blank">
              <u>Noémie Heuzé </u>
            </a>
          </li>
          <li v-if="!this.isMobile">Copyright 2024</li>
        </ul>
        <ul v-if="this.isMobile">
          <li>© 2024</li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script>
import ButtonComponent from "../components/Links/ButtonComponent.vue";
import data from "../data/data.json";

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import MotionPathPlugin from "gsap/MotionPathPlugin";
gsap.registerPlugin(ScrollTrigger, MotionPathPlugin);
export default {
  name: "FooterComponent",
  data() {
    return {
      data: data.contactInfo,
      menuItems: [
        { name: "Home", href: "/", anchor: "home", isAnchor: true },
        { name: "Works", anchor: "projectsList", isAnchor: true },
        { name: "About me", anchor: "aboutMe", isAnchor: true },
        { name: "Lab", href: "/lab", isAnchor: false },
      ],
      isMobile: false,
      listItems: null,
      meetCircleAnimation: null,
    };
  },
  components: {
    ButtonComponent,
  },
  watch: {
    $route() {
      this.$nextTick(() => {
        this.meetCircle();
      });
    },
  },
  mounted() {
    this.isMobile = window.innerWidth < 768;
    this.listItems = this.$el.querySelectorAll(".infoFooter_socials_box");
    ScrollTrigger.refresh();
    this.listItems.forEach((li) => {
      li.addEventListener("mouseenter", () => {
        this.animateArrow(li);
      });
      li.addEventListener("mouseleave", () => {
        this.animateArrowLeave(li);
      });
    });
    this.meetCircleAnimation = this.meetCircle(); // Stocke l'instance de l'animation meetCircle
  },
  beforeUnmount() {
    // Nettoyage des animations et instances de ScrollTrigger
    if (this.listItems) {
      this.listItems.forEach((li) => {
        li.removeEventListener("mouseenter", this.animateArrow);
      });
      this.listItems.forEach((li) => {
        li.removeEventListener("mouseleave", this.animateArrowLeave);
      });
    }
    if (this.meetCircleAnimation) {
      this.meetCircleAnimation.kill();
    }
    ScrollTrigger.getAll().forEach((st) => st.kill());
  },
  methods: {
    enterMenu(e) {
      if (this.isMobile) return;
      const menuHover = e.target.querySelector(".menuHover");
      gsap.to(menuHover, {
        duration: 0.4,
        scaleX: 1,
        transformOrigin: "left",
      });
    },
    leaveMenu(e) {
      if (this.isMobile) return;
      const menuHover = e.target.querySelector(".menuHover");
      gsap.to(menuHover, {
        duration: 0.4,
        scaleX: 0,
        transformOrigin: "right",
      });
    },
    animateUp() {
      if (this.isMobile) return;
      const tl = gsap.timeline();
      tl.to(this.$refs.cache, {
        y: "0%",
        duration: 0.4,
        ease: "power1.out",
      });
      tl.to(
        [this.$refs.cache.querySelector("span"), this.$refs.mailSvg],
        {
          y: "0%",
          duration: 0.4,
          ease: "power1.out",
        },
        "<50%"
      );
    },
    animateDown() {
      if (this.isMobile) return;
      const tl = gsap.timeline();
      tl.to([this.$refs.cache.querySelector("span"), this.$refs.mailSvg], {
        y: "-80%",
        duration: 0.4,
        ease: "power1.out",
      });
      tl.to(
        this.$refs.cache,
        {
          y: "100%",
          duration: 0.4,
          ease: "power1.out",
        },
        "<"
      );
      tl.to(
        [this.$refs.cache.querySelector("span"), this.$refs.mailSvg],
        {
          y: "50%",
          duration: 0.4,
          ease: "power1.out",
        },
        "<50%"
      );
      tl.to(
        this.$refs.rightSideA,
        {
          borderColor: "rgb(247, 255, 247, 0.2)",
          duration: 0.5,
        },
        "<50%"
      );
    },
    navigate(item) {
      if (item.isAnchor) {
        if (this.$route.name !== "Home") {
          this.$router.push({
            name: "Home",
            query: { scrollToSection: item.anchor },
          });
          setTimeout(() => {
            this.handleScrollToSection(item.anchor);
          }, 1000);
        } else {
          this.handleScrollToSection(item.anchor);
        }
      } else {
        // Navigation standard
        this.$router.push({ path: item.href });
        gsap.to(
          ".logo, .rightSide a, .rightSide_light_letter span, .rightSide_light_point, path, .rightSide_mail div",
          {
            color: "",
            border: "",
            fill: "",
            backgroundColor: "",
          }
        );
      }
    },
    handleScrollToSection(sectionId) {
      if (sectionId === "aboutMe") {
        this.$nextTick(() => {
          this.$root.$smoother.scrollTo("footer", true, "top bottom");
        });
      } else if (sectionId === "home") {
        this.$nextTick(() => {
          this.$root.$smoother.scrollTo("body", true, "top top");
          setTimeout(() => {
            ScrollTrigger.refresh();
          }, 1000);
        });
      } else {
        this.$nextTick(() => {
          this.scrollToSection(sectionId);
        });
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (section) {
        gsap.to(window, {
          scrollTo: {
            y: section,
            autoKill: false,
          },
          immediateRender: true,
          duration: 0.1,
        });
      } else {
        // Si la section n'est pas encore disponible, attend un peu et réessaye
        setTimeout(() => this.scrollToSection(sectionId), 100);
      }
    },

    scrollTopLogo() {
      gsap.to(window, {
        duration: 1,
        scrollTo: 0,
        ease: "power4.Out",
        onComplete: () => {
          ScrollTrigger.refresh();
        },
      });
    },
    animateArrow(li) {
      const arrowIcon = li.querySelector("svg:first-of-type");
      gsap.to(arrowIcon, {
        duration: 0.4,
        ease: "power1.inOut",
        overwrite: "auto",
        rotate: 45,
      });
    },
    animateArrowLeave(li) {
      const arrowIcon = li.querySelector("svg:first-of-type");
      gsap.to(arrowIcon, {
        duration: 0.4,
        ease: "power1.inOut",
        overwrite: "auto",
        rotate: 0,
      });
    },
    meetCircle() {
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: !this.isMobile ? this.$refs.circleRight : this.$refs.footer,
          start: !this.isMobile ? "top 80%" : "center bottom",
          end: !this.isMobile ? "bottom center" : "bottom bottom",
          scrub: 1,
          fastScrollEnd: true,
          onLeaveBack: () => {
            gsap.set(this.$refs.circleRight, { x: "150%" });
            gsap.set(this.$refs.rayure, { x: "150%" });
          },
        },
      });
      if (!this.isMobile) {
        tl.fromTo(
          ".text-animate",
          {
            css: {
              fontVariationSettings: "'wght' 700, 'wdth' 100",
            },
          },
          {
            ease: "linear",
            css: {
              fontVariationSettings: "'wght' 100, 'wdth' 100",
            },
          }
        );
      }

      tl.fromTo(
        this.$refs.circleRight,
        {
          x: "150%",
        },
        {
          x: "60%",
        },
        "<"
      );
      tl.fromTo(
        this.$refs.rayure,
        { x: "150%" },
        {
          x: "60%",
        },
        "<"
      );
      return tl;
    },
  },
};
</script>

<style scoped lang="scss">
@use "../styles/variables";

footer {
  background: variables.$primary-color;
  color: variables.$secondary-color;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  position: relative;
  z-index: 5;

  padding: 150px 110px 40px 110px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.ctaBox {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 60px;
  &_left {
    width: 50%;
    max-width: 580px;
    margin-right: 24px;
    &_title {
      display: flex;
      text-transform: uppercase;
      flex-wrap: wrap;
      margin-bottom: 80px;
      span {
        font-family: variables.$franie;
        font-size: variables.$font-size-80;
        font-weight: bold;
        &:nth-child(1) {
          margin-right: 30px;
        }
        &:nth-child(3) {
          margin-right: 30px;
        }
        &:nth-child(4) {
          margin-right: 30px;
        }
        &:nth-child(2) {
          font-style: italic;
          font-weight: bold;
        }
        &:nth-child(5) {
          font-style: italic;
          font-weight: bold;
        }
      }
    }
  }
  &_right {
    width: 50%;
    position: relative;
    display: flex;
    align-items: center;
    & div {
      position: absolute;
      border-radius: 50%;
      height: 100%;

      aspect-ratio: 1/1;
      border: 1px solid variables.$secondary-color;
    }
    &_circleLeft {
      left: 0;
      background: variables.$primary-color;
      overflow: hidden;
    }
    &_circleRight {
      left: 0;
      transform: translateX(150%);
    }
  }
}
.ctaBox_right_circleLeft {
  & img {
    height: 100%;
    width: auto;
  }
}
.rayure {
  position: absolute;
  left: 0;
  height: 100%;
  aspect-ratio: 1/1;
  transform: translateX(150%);
}
footer .button {
  background: variables.$secondary-color;
  color: variables.$primary-color;
  font-weight: 400;
}
.ctaFooter {
  background: variables.$secondary-color;
  color: variables.$primary-color;
  border: 1px solid variables.$secondary-color;
  &_cache {
    background: variables.$primary-color;
    & span {
      color: variables.$secondary-color;
    }
  }
}
.infoFooter {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  border-top: 1px solid variables.$secondary-color;
  padding-top: 40px;
  box-sizing: border-box;
  font-family: variables.$ibm;
  font-weight: 500;
  & ul li:not(:last-child) {
    margin-bottom: 16px;
  }
  &_menu li {
    width: max-content;

    position: relative;
  }
  &_socials {
    & li {
      display: flex;
      justify-content: space-between;
      width: 180px;
      cursor: pointer;
    }
    &_box {
      position: relative;
    }
  }
}
.menuHover {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: variables.$secondary-color;
  transform: scaleX(0);
  transform-origin: left;
  height: 1px;
}
.pathArrow {
  position: absolute;
  transform-origin: center;
  right: -3px;
  top: -38.5px;
}
@media screen and (min-width: 1930px) {
  footer {
    align-items: center;
    & .ctaBox {
      max-width: 1920px;
    }
    & .infoFooter {
      max-width: 1920px;
      width: 100%;
    }
  }
}
@media screen and (max-height: 1250px) {
  .ctaBox_right div {
    max-height: 27vw;
  }
}
@media screen and (max-height: 850px) {
  .ctaBox {
    margin-bottom: 60px;
    &_left {
      max-width: 550px;
      &_title span {
        font-size: 54px;
      }
    }
  }
}
@media screen and (max-width: 1560px) {
  .ctaBox {
    &_left {
      max-width: 400px;
      &_title span {
        font-size: 54px;
      }
    }
  }
}
@media screen and (max-width: 768px) {
  footer {
    padding: 140px 24px 24px 24px;
    height: max-content;
    min-height: 100vh;
  }
  .text-animate {
    font-weight: lighter !important;
  }
  .infoFooter {
    height: 100%;
    flex-wrap: wrap;
  }
  .infoFooter_menu,
  .infoFooter_socials,
  .infoFooter_status,
  .infoFooter_credits {
    width: max-content;
    margin-bottom: 0px;
    display: flex;
    justify-content: space-between;
  }
  .infoFooter_mob {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 80px;
  }

  .infoFooter_credits {
    width: 100%;
    & ul {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      max-height: 75px;
      justify-content: flex-end;
    }
  }
  .ctaBox {
    flex-direction: column;
    align-items: center;
    &_left {
      width: 100%;
      margin-right: 0px;
      max-width: 300px;
      align-self: flex-start;
      &_title {
        margin-bottom: 40px;
        & span {
          font-size: variables.$font-size-40;
          &:nth-child(1) {
            margin-right: 10px;
          }
        }
      }
    }
    &_right {
      width: 330px;
      height: 205px;
      & div {
        max-height: none;
      }
    }
  }
  .ctaFooter_mob {
    align-self: flex-start;
    margin-top: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>
