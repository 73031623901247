// navigationMixin.js
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

export default {
  methods: {
    goToSection(sectionId, routePath = "/") {
      if (sectionId === "contact") {
        this.scrollToSection(sectionId);
      } else if (sectionId === "aboutMe") {
        this.$router.push(routePath).then(() => {
          this.$nextTick(() => {
            ScrollTrigger.refresh();
            this.$root.$smoother.scrollTo("footer", true, "top bottom");
          });
        });
      } else if (sectionId === "home") {
        this.$router.push(routePath).then(() => {
          this.$root.$smoother.scrollTo(0, 0);
        });
      } else {
        this.$router.push(routePath).then(() => {
          this.$nextTick(() => {
            this.scrollToSection(sectionId);
          });
        });
      }
    },
    scrollToSection(sectionId) {
      const section = document.getElementById(sectionId);
      if (sectionId != "aboutMe") {
        setTimeout(() => {
          this.$root.$smoother.scrollTo(section, true, "top top"); // Scroll principal qui controle notamment le changement de project
        }, 1000);
      } else if (sectionId === "aboutMe") {
        this.$nextTick(() => {
          ScrollTrigger.refresh();

          this.$root.$smoother.scrollTo("#aboutMe", true, "top top");
        });
      }
      if (this.scrollContrast) {
        this.scrollContrast();
      }
    },
    goToRoute(routePath) {
      this.$router.push(routePath);
      this.$root.$smoother.scrollTo(0, 0);
    },
  },
  watch: {
    $route: function () {
      this.scrollToSection(this.$route.hash.replace("#", ""));
    },
  },
};
