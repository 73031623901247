<template>
  <nav ref="nav">
    <router-link to="/" class="logo" ref="logo" @click="scrollTopLogo"
      >Lyman</router-link
    >
    <button @click="openNav">
      <svg
        ref="cross"
        width="30"
        height="31"
        viewBox="0 0 30 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M29.6749 16.9992V13.6758H16.4992V0.5H13.1758V13.6758H0V16.9992H13.1758V30.1749H16.4992V16.9992H29.6749Z"
          fill="#F7FFF7"
        />
      </svg>
    </button>
    <div class="rightSide">
      <div class="rightSide_mail">
        <a ref="rightSideA" :href="'mailto:' + data.contactInfo.mail">{{
          data.contactInfo.mail
        }}</a>
        <div></div>
      </div>

      <div @click="switchLight" class="rightSide_light">
        <div class="rightSide_light_point" ref="rightSideLightPoint"></div>
        <div class="rightSide_light_letter">
          <span ref="off">FF</span>
          <span ref="on">N</span>
        </div>
      </div>
    </div>
    <transition name="menu-transition" @enter="enterMenu" @leave="leaveMenu">
      <div v-show="isOpen" class="menu">
        <ul>
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            @click="openNav"
            :data-content="item.name"
          >
            <a href="javascript:void(0);" @click="navigate(item)">
              <span>{{ item.name }}</span>
              <span>{{ item.name }}</span>
            </a>
          </li>
        </ul>
        <div class="mobileMenuMail">
          <a :href="'mailto:' + data.contactInfo.mail">{{
            data.contactInfo.mail
          }}</a>
          <div></div>
        </div>
      </div>
    </transition>
  </nav>
</template>

<script>
import data from "../data/data.json";
import gsap from "gsap";
import ScrollToPlugin from "gsap/ScrollToPlugin";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollSmoother from "gsap/ScrollSmoother";
import CustomEase from "gsap/CustomEase";
import navigationMixin from "../mixins/navigationMixin.js";

gsap.registerPlugin(ScrollToPlugin, ScrollTrigger, ScrollSmoother, CustomEase);

export default {
  name: "NavBar",
  data() {
    return {
      data,
      light: false,
      isOpen: false,
      isMobile: window.innerWidth < 768,
      menuItems: [
        { name: "Home", href: "/", isAnchor: false },
        { name: "Works", anchor: "projectsList", isAnchor: true },
        // { name: "About me", anchor: "aboutMe", isAnchor: true },
        { name: "Lab", href: "/lab", isAnchor: false },
        { name: "Contact", anchor: "contact", isAnchor: true },
      ],
      menuHoverDuration: 0.6,
      animationRefs: [],
      isAnimating: false,
    };
  },
  watch: {
    $route(to, from) {
      this.$nextTick(() => {
        // Vérifie si l'utilisateur navigue réellement vers une nouvelle page
        if (to.path !== from.path) {
          this.resetNavbarStyles(); // Appelle la méthode pour réinitialiser les styles
        }
        this.scrollContrast();
        if (to.query.scrollToSection) {
          this.scrollToSection(to.query.scrollToSection);
        }
      });
    },
  },

  mixins: [navigationMixin],
  mounted() {
    this.$nextTick(() => {
      this.scrollContrast();
    });
    gsap.set(document.querySelector(".logo, .rightSide_mail a"), {
      color: "#F7FFF7",
    });
  },
  beforeUnmount() {
    // Nettoyage des animations et instances de ScrollTrigger
    ScrollTrigger.getAll().forEach((st) => st.kill());
  },
  methods: {
    navigate(item) {
      if (item.isAnchor) {
        if (this.$route.name !== "Home") {
          this.$router.push({
            name: "Home",
            query: { scrollToSection: item.anchor },
          });
          if (this.isMobile) {
            setTimeout(() => {
              this.handleScrollToSection(item.anchor);
            }, 1500);
          } else {
            setTimeout(() => {
              this.handleScrollToSection(item.anchor);
            }, 1000);
          }
        } else {
          this.handleScrollToSection(item.anchor);
        }
      } else {
        // Navigation standard
        this.$router.push({ path: item.href });
      }
    },

    isTriggerVisible() {
      const triggerElement = document.querySelector("footer");
      if (!triggerElement) return false; // Assurez-vous que l'élément existe

      const triggerBounds = triggerElement.getBoundingClientRect();
      return (
        triggerBounds.bottom - 50 < window.innerHeight &&
        triggerBounds.bottom >= 0
      );
    },

    handleScrollToSection(sectionId) {
      this.$nextTick(() => {
        this.scrollToSection(sectionId);
        setTimeout(() => {
          ScrollTrigger.refresh();
        }, 500);
      });
    },

    scrollTopLogo() {
      gsap.to(window, {
        duration: 1,
        scrollTo: 0,
        ease: "power4.Out",
        onComplete: () => {
          ScrollTrigger.refresh();
        },
      });
    },

    openNav() {
      if (this.isAnimating) return; // Arrêter si une animation est en cours
      this.isAnimating = true; // Marquer le début d'une animation

      this.isOpen = !this.isOpen; // bascule l'état d'ouverture
      const cross = this.$refs.cross;
      gsap.to(cross, {
        rotation: this.isOpen ? 135 : 0,
        duration: 0.5,
        ease: "power2.inOut",
        onComplete: () => {
          this.isAnimating = false; // Marquer la fin de l'animation
          // Vérifier si on est dans la zone de contraste et si le menu est ouvert
        },
        onStart: () => {
          if (this.isTriggerVisible() && this.isOpen) {
            // Changer les couleurs en blanc
            gsap.to(
              ".logo, .rightSide a, .rightSide_light_letter span, .rightSide_light_point",
              {
                color: "#F7FFF7",
                duration: 0.2,
              }
            );
            gsap.to(".rightSide_light_point", {
              border: "1px solid #F7FFF7",
              duration: 0.2,
            });
            gsap.to(this.$refs.cross.querySelector("path"), {
              fill: "#F7FFF7",
              duration: 0.2,
            });
            gsap.to(".rightSide_mail div", {
              backgroundColor: "#F7FFF7",
              duration: 0.2,
            });
          }
        },
      });
    },

    enterMenu(el, done) {
      const enterTl = gsap.timeline({
        overwrite: "auto",
      });
      gsap.set("body", {
        overflow: "hidden",
      });
      enterTl.to(
        el,

        {
          y: "0%",
          duration: 0.8,
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
          onComplete: () => {
            done();

            this.$emit("updateMenuState", true); // Envoyer true quand le menu est ouvert
            this.attachHoverHandlers();
          },
        }
      );
      enterTl.fromTo(
        el.querySelectorAll("li a"),
        {
          opacity: 1,
          y: "100%",
        },
        {
          opacity: 1,
          y: 0,
          duration: 1.3,
          stagger: 0.1,
          ease: "expo.inOut",
        },
        "<55%"
      );
    },

    leaveMenu(el, done) {
      const leaveTl = gsap.timeline({
        overwrite: "auto",
        onStart: this.detachHoverHandlers,
      });
      leaveTl.fromTo(
        el.querySelectorAll("li a"),
        {
          opacity: 1,
          y: 0,
        },
        {
          opacity: 1,
          y: "100%",
          duration: 0.8,
          ease: "expo.inOut",
        }
      );
      leaveTl.to(
        el,

        {
          y: "-100%",
          duration: 0.5,

          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
          onComplete: () => {
            done();
            this.$emit("updateMenuState", false);
          },
        }
      );

      if (this.isTriggerVisible()) {
        gsap.to(
          ".logo, .rightSide a, .rightSide_light_letter span, .rightSide_light_point",
          {
            color: "#0a0a0a",
            delay: 0.5 + 0.8,
          }
        );
        gsap.to(".rightSide_light_point", {
          border: "1px solid #0a0a0a",
          delay: 0.5 + 0.8,
        });
        gsap.to(this.$refs.cross.querySelector("path"), {
          fill: "#0a0a0a",
          delay: 0.5 + 0.8,
        });
        gsap.to(".rightSide_mail div", {
          backgroundColor: "#0a0a0a",
          delay: 0.5 + 0.8,
        });
      }

      gsap.set("body", {
        overflow: "visible",
      });
    },
    attachHoverHandlers() {
      if (this.isMobile) return;
      const menuItems = document.querySelectorAll(".menu li");
      menuItems.forEach((item) => {
        item.addEventListener("mouseenter", this.hoverStart);
        item.addEventListener("mouseleave", this.hoverEnd);
      });
    },

    detachHoverHandlers() {
      if (this.isMobile) return;
      const menuItems = document.querySelectorAll(".menu li");
      menuItems.forEach((item) => {
        item.removeEventListener("mouseenter", this.hoverStart);
        item.removeEventListener("mouseleave", this.hoverEnd);
      });
    },
    hoverStart(event) {
      let target = event.currentTarget;

      gsap.to(target.querySelector("a"), {
        y: "-100%",
        ease: "power3.Out",
        duration: 0.4,
      });
    },

    hoverEnd(event) {
      let target = event.currentTarget;

      gsap.to(target.querySelector("a"), {
        y: "0",
        ease: "power3.Out",
        duration: 0.4,
      });
    },

    scrollContrast() {
      this.contrastTrigger = ScrollTrigger.create({
        trigger: "footer",
        start: !this.isMobile ? "top-=50 top" : "top top",
        end: !this.isMobile ? "top top" : "top-=70vh top",
        scrub: true,
        immediateRender: false,
      });
      const tl = gsap.timeline({
        scrollTrigger: {
          trigger: "footer",
          start: !this.isMobile ? "top-=50 top" : "top top",
          end: !this.isMobile ? "top top" : "top-=70vh top",
          scrub: true,
          immediateRender: false,
        },
      });

      tl.to(this.$refs.nav, {
        backgroundColor: "rgb(10, 10, 10, 0)",
      });
      tl.to(
        ".logo, .rightSide a, .rightSide_light_letter span, .rightSide_light_point",
        {
          color: "#0a0a0a",
        },
        "<"
      );
      tl.to(
        ".rightSide_light_point",
        {
          border: "1px solid #0a0a0a",
        },
        "<"
      );
      tl.to(
        this.$refs.cross.querySelector("path"),
        {
          fill: "#0a0a0a",
        },
        "<"
      );
      tl.to(
        ".rightSide_mail div",
        {
          backgroundColor: "#0a0a0a",
        },
        "<"
      );
      this.animationRefs.push(tl);
    },
    resetNavbarStyles() {
      gsap.to(
        [
          ".logo, .rightSide a, .rightSide_light_letter span, .rightSide_light_point,nav button svg path,  .rightSide_mail div",
        ],
        {
          color: "#F7FFF7",
          border: "",
          fill: "#F7FFF7",
          backgroundColor: "",
          overwrite: true,
        }
      );
      gsap.set(this.$refs.cross, {
        rotation: 0,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@use "../styles/variables";

.backFilter {
  background: rgb(10, 10, 10, 0.2);
}
nav {
  width: 100%;
  max-width: 1920px;
  display: flex;
  justify-content: space-between;
  padding: 20px 110px;
  box-sizing: border-box;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 5;

  & button {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: 11;

    & svg {
      height: 20px;
    }
  }
  & a {
    color: variables.$primary-color;
  }
}

.logo {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 20px;
  z-index: 1001;
}
.rightSide {
  font-size: variables.$font-size-16;
  font-weight: normal;
  font-family: variables.$ibm;
  display: flex;
  align-items: center;
  z-index: 11;

  &_mail {
    position: relative;
    & div {
      position: absolute;
      display: block;
      width: 100%;
      height: 1px;
      background-color: variables.$primary-color;
      transform: translateY(-1px);
    }
  }

  &_light {
    display: none;
    align-items: center;
    margin-left: 165px;
    cursor: pointer;
    &_point {
      width: 16px;
      height: 16px;
      border-radius: 50%;
      border: 1px solid variables.$primary-color;
      margin-right: 4px;
    }
    &_letter {
      display: flex;
      flex-direction: column;
      height: 20px;
      & span:nth-child(2) {
        opacity: 0;
      }
    }
  }
}
.nav-links {
  display: flex;
  & li:not(:last-child) {
    margin-right: 20px;
  }
}
.menu {
  position: absolute;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  width: 100vw;
  height: 100vh;
  background-color: #0a0a0a;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  padding: calc(80px + 60px) 110px 80px 110px;
  box-sizing: border-box;
  & ul {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    width: 100%;
    height: 100%;

    & li {
      width: max-content;
      display: flex;
      justify-content: center;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      height: 5vw;
      & a {
        width: 100%;
        display: flex;
        flex-direction: column;
        & span {
          text-decoration: none;
          font-size: 5vw;
          height: 5vw;
          color: variables.$primary-color;
          font-weight: bold;
          text-transform: uppercase;
          font-family: variables.$franie;
        }
      }
    }
  }
}

.mobileMenuMail {
  display: none;
}
@media screen and (min-width: 1930px) {
  nav {
    transform: translateX(-50%);
    padding: 20px 0px;
    left: 50%;
  }
}
@media screen and (max-width: 1560px) {
  nav {
    padding: 20px 55px;
  }
  .boxAnimations {
    right: 55px;
  }
}

@media screen and (max-width: 768px) {
  nav {
    padding: 24px 24px 16px 24px;
    width: 100vw;
    & .rightSide {
      display: none;
    }
    & button {
      position: static;
      transform: translateX(0%);
    }
  }
  .boxAnimations {
    display: none;
  }
  .menu {
    flex-direction: column;
    padding: 0;
    & ul {
      justify-content: center;
      padding: 0;
      align-items: center;
      & li {
        height: 80px;
        & a span {
          font-size: variables.$font-size-80-mob;
          padding: 24px 0;
          width: 100%;
        }
      }
    }
  }
  .mobileMenuMail {
    display: block;
    width: max-content;
    & a {
      color: variables.$secondary-color;
      font-size: variables.$font-size-16;
      font-weight: normal;
      font-family: variables.$ibm;
      cursor: pointer;
    }
    & div {
      display: block;
      width: 100%;
      height: 1px;
      background-color: variables.$secondary-color;
      transform: translateY(-1px);
    }
  }
}
</style>
