<template>
  <div class="introFirst" ref="introFirst">
    <div
      class="progress-bar"
      :style="{ height: progressBarHeight, width: progressBarWidth }"
    ></div>
    <div class="counter">{{ counter }}%</div>
  </div>
</template>

<script>
import gsap from "gsap";

export default {
  name: "IntroFirst",
  data() {
    return {
      counter: 0,
      progressBarWidth: 0,
      progressBarHeight: "10px", // Hauteur initiale
    };
  },
  mounted() {
    this.animateCounter();
  },
  methods: {
    animateCounter() {
      const obj = { counter: 1 };
      gsap.to(obj, {
        counter: 100,
        duration: 3,
        delay: 0.5,
        onUpdate: () => {
          this.counter = Math.floor(obj.counter);
          this.progressBarWidth = this.counter + "%"; // Mettre à jour la largeur de la barre de progression
          this.progressBarHeight = "10px"; // Réinitialiser la hauteur
        },
        ease: "power3.Out",
        onComplete: () => {
          this.leaveIntro();
        },
      });
    },
    leaveIntro() {
      this.$root.$smoother.scrollTo(0, 0);
      const tl = gsap.timeline({
        onComplete: () => {
          this.$store.commit("SET_APP_MOUNTED", false);
        },
      });
      tl.to(".progress-bar", {
        duration: 1,
        delay: 1,
        height: "100vh",
        ease: "power3.inOut",
      });
      tl.to(
        this.$refs.introFirst,
        {
          duration: 0.5,
          opacity: 0,
          ease: "power3.inOut",
          onComplete: () => {
            this.$refs.introFirst.style.display = "none";
            document.body.style.overflow = "visible";
          },
        },
        "<70%"
      );
    },
  },
};
</script>

<style scoped lang="scss">
@use "../styles/variables";
.introFirst {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  background: variables.$primary-color;
  z-index: 1000;
  overflow: hidden;
  display: flex;

  & .counter {
    color: variables.$secondary-color;
    font-size: 5vw;
    font-family: variables.$franie;
    font-weight: normal;
    text-transform: uppercase;
    position: absolute;
    right: 16px;
    top: 16px;
  }
}
.progress-bar {
  background-color: variables.$secondary-color;
  transition: width 0.3s ease-out;
  position: absolute;
  will-change: width;
}
@media screen and (max-width: 768px) {
  .introFirst {
    & .counter {
      font-size: variables.$font-size-80;
    }
  }
}
</style>
