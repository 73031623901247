<template>
  <!-- <div class="gridDesign">
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
    <div class="gridDesign_grid"></div>
  </div> -->
  <NavBar @updateMenuState="handleMenuState" />
  <BGComponent />
  <div class="noise-bg"></div>
  <IntroFirst />
  <div id="smooth-wrapper">
    <div id="smooth-content">
      <div class="appBox" ref="appBox">
        <router-view :key="routeKey" v-slot="{ Component, route }">
          <Transition name="fade" mode="out-in">
            <component :is="Component" :key="route.fullPath" />
          </Transition>
        </router-view>
      </div>

      <FooterComponent />
    </div>
  </div>
  <div
    :class="[
      'g-cursor',
      { 'g-cursor_hover': hover },
      { 'g-cursor_hide': hideCursor },
    ]"
    v-if="!isMobile"
  >
    <div :style="cursorCircle" class="g-cursor__circle"></div>
    <div class="g-cursor__point" ref="point" :style="cursorPoint"></div>
  </div>
  <TransitionComponent
    v-if="showTransition"
    @transition-end="handleTransitionEnd"
    :target-path="nextPath"
  />
</template>

<script>
import NavBar from "./components/NavBar.vue";
import FooterComponent from "./sections/FooterComponent.vue";
import TransitionComponent from "./animations/TransitionComponent.vue";
import IntroFirst from "./animations/IntroFirst.vue";
import BGComponent from "./animations/BGComponent.vue";
import gsap from "gsap";
import ScrollSmoother from "gsap/ScrollSmoother";
import ScrollTrigger from "gsap/ScrollTrigger";
import { eventBus } from "./EventBus.js";

gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
export default {
  name: "App",
  components: {
    NavBar,
    FooterComponent,
    TransitionComponent,
    IntroFirst,
    BGComponent,
  },
  data() {
    return {
      routeKey: 0,
      showTransition: false,
      isMenuOpen: false,
      nextPath: "",
      xChild: 0,
      yChild: 0,
      xParent: 0,
      yParent: 0,
      hover: false,
      hideCursor: true,
      isMobile: window.innerWidth < 768,
    };
  },
  watch: {
    $route(to, from) {
      this.nextPath = to.path;
    },
  },
  created() {
    eventBus.on("startTransition", this.handleStartTransition);
  },
  methods: {
    handleMenuState(isMenuOpen) {
      this.isMenuOpen = isMenuOpen;
    },
    handleStartTransition() {
      if (!this.isMenuOpen) {
        this.showTransition = true;
      }
    },
    handleTransitionEnd() {
      this.showTransition = false;
    },
    refreshOnResize() {
      ScrollTrigger.refresh();
    },
    initCursor() {
      this.xChild = window.innerWidth / 2;
      this.yChild = window.innerHeight / 2;
      this.xParent = this.xChild - 15;
      this.yParent = this.yChild - 15;
      this.hideCursor = false;
    },
    moveCursor(e) {
      const update = () => {
        this.xChild = e.clientX;
        this.yChild = e.clientY;
        setTimeout(() => {
          this.xParent = e.clientX - 15;
          this.yParent = e.clientY - 15;
        }, 100);
      };
      requestAnimationFrame(update);
    },
  },
  mounted() {
    this.$root.$smoother = ScrollSmoother.create({
      smooth: 1.5,
      wrapper: "#smooth-wrapper",
      content: "#smooth-content",
      smoothTouch: 0.1,
    });

    this.$store.commit("SET_APP_MOUNTED", true);
    this.initCursor();
    document.addEventListener("mousemove", this.moveCursor);
    document.addEventListener("mouseleave", (e) => {
      this.hideCursor = true;
    });
    document.addEventListener("mouseenter", (e) => {
      this.hideCursor = false;
    });
  },
  computed: {
    cursorCircle() {
      return `transform: translateX(${this.xParent}px) translateY(${this.yParent}px) translateZ(0) translate3d(0, 0, 0);`;
    },
    cursorPoint() {
      return `transform: translateX(${this.xChild - 3}px) translateY(${
        this.yChild - 3
      }px) translateZ(0) translate3d(0, 0, 0);`;
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.refreshOnResize);
  },
};
</script>

<style lang="scss">
.gridDesign {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  opacity: 0.1;
  pointer-events: none;
  display: flex;
  justify-content: space-between;
  padding: 0px 110px;
  box-sizing: border-box;
  & .gridDesign_grid {
    height: 100%;
    width: 100%;
    background: #b9aeae;
  }
  & div:not(:last-child) {
    margin-right: 2px;
  }
}
@media screen and (max-width: 1560px) {
  .gridDesign {
    padding: 0px 55px;
  }
}
@media screen and (max-width: 768px) {
  .gridDesign {
    padding: 0px 24px;
  }
}
/* Composant sortant */
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-leave-to {
  opacity: 0;
}

/* Composant entrant */
.fade-enter-active {
  transition: opacity 0.5s 1.5s ease; /* Délai de 1.5s ajouté ici */
}

.fade-enter-from {
  opacity: 0;
}
.g-cursor {
  &_hide {
    opacity: 0;
    width: 60px;
    height: 60px;
    transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
  }

  &__circle {
    pointer-events: none;
    user-select: none;
    top: 0;
    left: 0;
    position: fixed;
    width: 30px;
    height: 30px;
    border: 1px solid #f7fff7;
    mix-blend-mode: difference;
    border-radius: 100%;
    z-index: 5555;
    transition: opacity 0.6s ease;
  }

  &__point {
    top: 0;
    left: 0;
    position: fixed;
    width: 10px;
    height: 10px;
    pointer-events: none;
    user-select: none;
    border-radius: 100%;
    background: #f7fff7;
    mix-blend-mode: difference;
    z-index: 55555555;
    will-change: transform;
  }

  &_hover {
    .g-cursor__circle {
      opacity: 0;
      width: 60px;
      height: 60px;
      transition: width 0.6s ease, height 0.6s ease, opacity 0.6s ease;
    }
  }
}
</style>
