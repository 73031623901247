<template>
  <div class="transition-container" ref="container">
    <div class="transition-inner" ref="inner">
      <div class="titleBox">
        <h2 ref="title">{{ h2Content }}</h2>
      </div>
    </div>
  </div>
</template>

<script>
import gsap from "gsap";
import CustomEase from "gsap/CustomEase";

gsap.registerPlugin(CustomEase);
export default {
  name: "TransitionComponent",
  data() {
    return {
      isMobile: window.innerWidth < 768,
    };
  },
  props: {
    targetPath: {
      type: String,
      required: true,
    },
  },
  computed: {
    h2Content() {
      switch (this.targetPath) {
        case "/lab":
          return "LAB.";
        case "/project/noemieheuze":
          return "NOEMIE HEUZE";
        case "/project/fokusstudio":
          return "FOKUS STUDIO";
        case "/project/ludstrom":
          return "LUDSTROM";
        case "/project/amplitude":
          return "AMPLITUDE";
        case "/project/kanap":
          return "KANAP";
        case "/project/jeff":
          return "JEFF SHERWOOD";
        case "/project/openmedia":
          return "OPEN MEDIA";
        case "/":
          return "HOME";
        default:
          return "";
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.dynamicAnimation();
    });
  },
  methods: {
    dynamicAnimation() {
      const tl = gsap.timeline({
        onComplete: () => {
          this.$emit("transition-end");
        },
      });
      tl.to(
        this.$refs.container,
        {
          duration: 0.8,
          transform: "translateY(0%)",
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
        },
        "<"
      );

      tl.to(
        this.$refs.inner,
        {
          duration: 0.8,
          transform: "translateY(0%)",
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
        },
        "<"
      );
      tl.to(
        this.$refs.title,
        {
          duration: 0.8,
          transform: "translateY(0%)",
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
        },
        "<50%"
      );
      tl.to(this.$refs.inner, {
        duration: 1.5,
        transform: "translateY(100%)",
        ease: CustomEase.create(
          "custom",
          "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
        ),
      });

      tl.to(
        this.$refs.container,
        {
          duration: 1.5,
          transform: "translateY(-100%)",
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
        },
        "<"
      );
      tl.to(
        this.$refs.title,
        {
          duration: 0.8,
          transform: "translateY(-100%)",
          ease: CustomEase.create(
            "custom",
            "M0,0 C0,0 0.24,-0.013 0.411,0.048 0.625,0.124 0.687,0.645 0.803,0.84 0.898,0.999 1,1 1,1 "
          ),
        },
        "<5%"
      );
    },
  },
};
</script>

<style scoped lang="scss">
@use "../styles/variables";
.transition-container {
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 999;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  pointer-events: none;
  will-change: transform;
  transform: translateY(100%);
}
.transition-inner {
  & h2 {
    opacity: 1;
    font-size: variables.$font-size-40;
    font-weight: bold;
    color: variables.$secondary-color;
    transform: translateY(100%);
    will-change: transform;
  }
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: variables.$primary-color;
  pointer-events: none;
  will-change: transform;
  transform: translateY(-100%);
}
.titleBox {
  overflow: hidden;
}
</style>
